import React from "react";
import "./VideoContent.css"; // Import your CSS for styling

const VideoContent = () => {
  const youtubeVideoUrl = "https://www.youtube.com/embed/YOUR_VIDEO_ID"; // Replace with your YouTube video ID
  const jyothishyamContent = `
    Jyothishyam is a platform dedicated to providing insights and guidance based on ancient astrology and horoscopic readings.
    Our services include personalized predictions, consultations, and much more.
  `; // Replace with your actual Jyothishyam content

  return (
    <div className=" col-12 ">
      <div className="video-section ">
       

        <iframe
          width="100%"
          height="640"
          className="videocurve"
          src="https://www.youtube.com/embed/dt-Y6LAkssQ?si=1MWH8J3JnPdI_nva"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
        <div className="col-12 justify-content-center align-items-center  d-flex">
        <button
          className=" col-12 see-more-button position-absolute "
          onClick={() =>
            window.open(
              "https://youtube.com/@abcmalayalamjyothisham?si=7O8cEFcGYCeWb_sR ",
              "_blank"
            )
          } // Replace with your YouTube channel link
        >
          കൂടുതല്‍ കാണുക
        </button>
        </div>
      </div>
    </div>
  );
};

export default VideoContent;
