import React, { useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
import { useTranslation } from 'react-i18next';

import contaimhg from '../public/Assets/contaimhg.png'

const Contact = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    message: "",
  });

  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handlePhoneChange = (value) => {
    setFormData({ ...formData, phone: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await axios.post("https://lunarsenterprises.com:5005/jyothisham/contact-us", {
        first_name: formData.firstName,
        last_name: formData.lastName,
        mobile: formData.phone,
        email: formData.email,
        message: formData.message,
      });
      setLoading(false);
      setShowModal(true);
      setFormData({
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        message: "",
      });
    } catch (error) {
      setLoading(false);
      console.error("There was an error sending the message!", error);
    }
  };

  return (
    <>
      <div className="herobanner">
        <div className="container align-items-center justify-content-center d-flex">
          <div className="col-12 align-items-center justify-content-center d-flex">
            <div className="col-12 col-md-6 col-lg-6 col-xl-6">
              {/* <p className='bannersub'>
              {t('Dive into the mystical world of astrology and discover the secrets written in the stars.Our experts provide insights and guidance based on your unique astrological profile.')}
              </p> */}
            </div>
          </div>
        </div>
      </div>


      <div className=" bgcolorunder d-flex align-items-center justify-content-center" style={{ minHeight: '100vh' }}>
  <div className="">
    <div className="container">
      <div className="underhorse col-12">
        <label className="underhorsemain text-black">ഞങ്ങളെ സമീപിക്കുക</label>

        <div className="col-12 mt-5 container justify-content-center align-items-center d-flex">
          <div className="row container">
            <div className="col-12">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6 mt-2 ">
                    <h6 className="text-black bookname">പേര്</h6>
                    <input
                      className="form-control inputbook"
                      placeholder="പേര്"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="col-md-6 mt-2">
                    <h6 className="bookname">അവസാന പേര്</h6>
                    <input
                      className="form-control inputbook"
                      placeholder="അവസാന പേര്"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-md-6 mt-2">
                    <h6 className="bookname">നമ്പർ*</h6>
                    <PhoneInput
                      country={"in"}
                      value={formData.phone}
                      onChange={handlePhoneChange}
                      inputStyle={{ height: "50px" }}
                      inputClass="form-control inputbook"
                      required
                    />
                  </div>
                  <div className="col-md-6 mt-3">
                    <h6 className="bookname">ഇമെയിൽ</h6>
                    <input
                      className="form-control inputbook"
                      type="email"
                      placeholder="ഇമെയിൽ"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>
                <div className="mt-3 col-12">
                  <h6 className="bookname">സന്ദേശം</h6>
                  <input
                    className="form-control inputbooknsh"
                    placeholder="സന്ദേശം"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                  />
                </div>
                <Button
                  className="custom-button mt-3"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    "സന്ദേശം അയയ്ക്കുക"
                  )}
                </Button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


      <Modal 
      centered
      show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Success</Modal.Title>
        </Modal.Header>
        <Modal.Body>Your message has been sent successfully.Our team Connect soon!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Contact;
