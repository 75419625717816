// StickyYouTubeIcon.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import '../components/StickyYouTubeIcon.css'; // Create this file for CSS styling

const StickyYouTubeIcon = () => {
  return (
    <div className="sticky-youtube-icon">
      <a
        href="https://youtube.com/@abcmalayalamjyothisham?si=7O8cEFcGYCeWb_sR "
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faYoutube} size="2x" />
      </a>
    </div>
  );
};

export default StickyYouTubeIcon;
