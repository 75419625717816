import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js'; // Ensure you import the bundled JS file
import { Route, Routes } from "react-router-dom";
import Header from './components/Header';
import HomePage from './pages/Home';

import './styles/globals.css';
import './styles/homepage.css';
import './styles/responsive.css';
import './styles/timeline.css';
import './styles/elementor.css';
import './styles/multiRangeSlider.css';
import './styles/formandpopup.css';
import Footer from './components/Footer';
import Ourteamspage from './Ourteampage';
import ServiceDetail from './services/[service]';
import Blog from './pages/blogs/allblogs';
import Contact from './pages/contact';
import TuitionForm from './pages/forivew/forivew';
import StickyYouTubeIcon from './components/StickyYouTubeIcon';

function App() {
  return (
    <>
          <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/Ourteamspage" element={<Ourteamspage />} />
        <Route path="/services/:service" element={<ServiceDetail />} />
        <Route path="/blogs/allblogs" element={<Blog />} />
        <Route path="/contact" element={<Contact />} />

      </Routes>
      <StickyYouTubeIcon />

      <Footer />
      </>

  );
}

export default App;
