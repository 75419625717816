import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Container } from 'react-bootstrap';
import '../Astrologhy/Astrologhy.css'; // Import the updated CSS
import axios from 'axios';

const AstrologyProductCard = ({ product }) => (
  <Col md={3} sm={6} xs={12} className="mb-4">
    <Card className="product-card">
      <div className="image-container">
        <Card.Img variant="" src={`https://lunarsenterprises.com:5005/${product.s_image}`} />
    
      </div>
      <div className='carviewstyle'>
      <Card.Body className="card-body-scroll">
        <label className='productsizenameastro text-black'>{product.s_name}</label>
       
        <div className='btnstyle mt-3' >
        <a className="dropdown-item" href={`/services/${encodeURIComponent(product.s_name.toLowerCase().replace(/\s+/g, '-'))}`}>
        കൂടുതല്‍ കാണുക

      </a>
        </div>
      </Card.Body>
      </div>
    </Card>
  </Col>
);


const AstrologyProductsGrid = () => {
  const [servicesApi, setServicesApi] = useState([]);
  const [displayedProducts, setDisplayedProducts] = useState([]);

  // Function to get 8 random services from the list
  const getRandomProducts = (products, count) => {
    const shuffled = [...products].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, count); // Get the first 8 shuffled items
  };

  useEffect(() => {
    const servicesList = async () => {
      try {
        const servicesResp = await axios.get('https://lunarsenterprises.com:5005/jyothisham/list/service');
        setServicesApi(servicesResp.data.data);
        setDisplayedProducts(getRandomProducts(servicesResp.data.data, 8)); // Initialize with 8 random products
      } catch (error) {
        console.error(error);
      }
    };

    servicesList();
  }, []);

  // Change products every 20 minutes (1200000 ms)
  useEffect(() => {
    const interval = setInterval(() => {
      if (servicesApi.length > 0) {
        setDisplayedProducts(getRandomProducts(servicesApi, 8));
      }
    }, 1200000); // 20 minutes in milliseconds

    return () => clearInterval(interval); // Clean up interval on component unmount
  }, [servicesApi]);

  return (
    <Container>
      <Row>
        {displayedProducts.map((product) => (
          <AstrologyProductCard key={product.s_id} product={product} />
        ))}
      </Row>
    </Container>
  );
};

export default AstrologyProductsGrid;
