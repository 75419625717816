import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";

// Import zodiac images
import taurus from "../../public/Assets/taurus.png";
import gemini from "../../public/Assets/gemini.png";
import cancer from "../../public/Assets/cancer.png";
import Leo from "../../public/Assets/Leo.png";
import Virgo from "../../public/Assets/Virgo.png";
import Libra from "../../public/Assets/Libra.png";
import Scorpio from "../../public/Assets/Scorpio.png";
import cap from "../../public/Assets/cap.png";
import aqu from "../../public/Assets/aqu.png";
import pis from "../../public/Assets/pis.png";
import sag from "../../public/Assets/sag.png";
import aries from "../../public/Assets/aries.png";

const HoroscopeComponent = () => {
  const [selectedOption, setSelectedOption] = useState("daily");
  const [horoscopeData, setHoroscopeData] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedBox, setSelectedBox] = useState(null);
  const [dateText, setDateText] = useState(""); // New state for dynamic date text
  const [cDescription, setCDescription] = useState(""); // New state for description

  const boxes = [
    { id: 1, image: aries, name: "മേടം" }, // Aries
    { id: 2, image: taurus, name: "ഇടവം" }, // Taurus
    { id: 3, image: gemini, name: "മിഥുനം" }, // Gemini
    { id: 4, image: cancer, name: "കർക്കിടകം" }, // Cancer
    { id: 5, image: Leo, name: "ചിങ്ങം" }, // Leo
    { id: 6, image: Virgo, name: "കന്നി" }, // Virgo
    { id: 7, image: Libra, name: "തുലാം" }, // Libra
    { id: 8, image: Scorpio, name: "വൃശ്ചികം" }, // Scorpio
    { id: 9, image: cap, name: "ധനു" }, // Capricorn
    { id: 10, image: aqu, name: "മകരം" }, // Aquarius
    { id: 11, image: sag, name: "കുംഭം" }, // Sagittarius
    { id: 12, image: pis, name: "മീനം" }, // Pisces
  ];

  // Handle option change
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  // Fetch data from API
  useEffect(() => {
    const fetchHoroscopeData = async () => {
      const Period = {
        status: selectedOption,
      };
      try {
        const response = await axios.post(
          "https://lunarsenterprises.com:5005/jyothisham/list/weekly/horoscope",
          Period
        );
        setHoroscopeData(response.data.data); // Assuming data is an array with multiple horoscope entries

        // Set the dynamic date text and description
        const dataItem = response.data.data[0]; // Get the first item
        if (dataItem) {
          if (selectedOption === "daily") {
            setDateText(`${dataItem.ww_time_period}  `);
          } else if (selectedOption === "weekly") {
            setDateText(`${dataItem.ww_time_period} `);
          } else {
            setDateText(`${dataItem.ww_time_period} `);
          }
        }

        if (dataItem) {
          if (selectedOption === "daily") {
            setCDescription(`${dataItem.ww_c_description}`);
          } else if (selectedOption === "weekly") {
            setCDescription(`${dataItem.ww_c_description}`);
          } else {
            setCDescription(` ${dataItem.ww_c_description}`);
          }
        }
      } catch (error) {
        console.error("Error fetching horoscope data:", error);
      }
    };
    fetchHoroscopeData();
  }, [selectedOption]);

  // Get content based on selected option (daily/weekly/monthly)
  const getHoroscopeContent = () => {
    return (
      <div className="scrollable-container">
        <label className="productsizenamehors text-black">{dateText}</label>
        <label className="productsizenamehors text-black">{cDescription}</label> {/* Use the dynamic dateText */}
        {/* Use the dynamic dateText */}
        <div className="box-grid">
          {horoscopeData?.map((signData) =>
            signData.weekly.map((box) => {
              const zodiacIndex = boxes.findIndex(
                (b) => b.name === box.w_sign_name
              ); // Find the correct index in boxes
              return (
                <div
                  className="box"
                  key={box.w_sign_name} // Unique key
                  onClick={() => handleShowModal(box)}
                >
                  <img
                    src={zodiacIndex !== -1 ? boxes[zodiacIndex].image : ""}
                    alt={box.w_sign_name}
                  />
                    < label className="productsizenamehors text-black">{box.w_sign_name} രാശി</label>
                </div>
              );
            })
          )}
        </div>
      </div>
    );
  };

  // Show modal
  const handleShowModal = (box) => {
    setSelectedBox(box);
    setShow(true);
  };

  // Close modal
  const handleClose = () => {
    setShow(false);
  };

  const renderSelectedBoxDetails = () => {
    if (!selectedBox) {
      return (
        <div className="rashi-phalam-container mt-3">
          < label className="productsizenamehorstitle text-black">രാശി ഫലം</label>
           < label className="productsizenamehors text-black">
            രാശി ഫലം (Rashi Phalam) എന്നത് ജാതകശാസ്ത്രത്തിലെ പ്രധാന ഘടകമാണ്, ഇത്
            ഒരു വ്യക്തിയുടെ ജനനരാശി, ഗ്രഹസ്ഥാനങ്ങൾ, നക്ഷത്രങ്ങൾ, തുടങ്ങിയവയുടെ
            അടിസ്ഥാനത്തിൽ എങ്ങനെ അവരുടെ ഭാവി പ്രവചിക്കാമെന്ന് പറയുന്നു.
          </label>

           < label className="productsizenamehorstitle text-black">രാശി ഫലത്തിന്റെ അടിസ്ഥാന വിവരങ്ങൾ:</label>

           < label className="productsizenamehorstitle text-black">ജാതക രാശി:</label>
           < label className="productsizenamehors text-black">
            ജാതകശാസ്ത്രത്തിൽ 12 രാശികൾ ഉണ്ട് (മേഷം, മിതുനം, കടകം, സിംഹം,
            കന്നി, തുലാം, വെരഷികം, ധനുശ്ശയം, മകരം, കുംഭം, മീനം). ഓരോ രാശിയുടെയും
            പ്രത്യേക ഗുണങ്ങളും ദോഷങ്ങളും ഉണ്ട്, അതിനാൽ, ജനിച്ച സമയം, ദിവസവും,
            മാസവും, വർഷവും, ഗ്രഹങ്ങളുടെ സ്ഥിതിയും നോക്കി, ആ വ്യക്തിക്ക്
            അനുയായമായ ഫലങ്ങൾ പ്രവചിക്കുന്നതാണ് രാശി ഫലം.
          </label>

           < label className="productsizenamehorstitle text-black">ഗ്രഹങ്ങളുടെ സ്ഥാനം:</label>
           < label className="productsizenamehors text-black">
            ഓരോ ഗ്രഹത്തിനും (സൂര്യൻ, ചന്ദ്രൻ, ബുധൻ, ഈശ്വരം, ശുക്രൻ, ശനി, രാഹു,
            കെതു) തങ്ങളുടെ പ്രത്യേക സ്വഭാവം ഉണ്ട്. ഇവയുടെ ജനനചാർട്ടിലെ സ്ഥാനം,
            അവയുടെ ദോഷഫലവും, സുഖഫലവും എങ്ങനെ ഉണ്ടാകുമെന്ന് വ്യക്തിയുടെ
            ജീവിതത്തിൽ പ്രവചിക്കപ്പെടുന്നു.
          </label>

          {/*  < label className="productsizenamehorstitle text-black">നക്ഷത്രം:</h4>
           < label className="productsizenamehors text-black">
          ഓരോ ജാതകത്തിലെ 27 നക്ഷത്രങ്ങൾ (പ്രതി നക്ഷത്രത്തിനു അനുയായമായ ഒരു ഗ്രഹത്തിന്റെ സ്ഥിതി) അനുയായമായ ഒരു ഫലം നൽകുന്നു. ഈ ഫലങ്ങൾ വ്യക്തിയുടെ വ്യക്തിത്വത്തെ, ഭാവി സംഭവങ്ങളെ, ബന്ധങ്ങളെ, സാമ്പത്തിക സാഹചര്യം തുടങ്ങിയവയെ കുറിച്ചുള്ള സൂചനകൾ നൽകുന്നു.


          </p> */}
        </div>
      );
    }

    return (
      <div className="selected-box-details mt-4">
        {/* <img src={selectedBox.image} alt={selectedBox.w_sign_name} /> */}
        <label className="productsizenamehors text-black">{selectedBox.w_sign_name} രാശി</label>
        <label className="productsizenamehors text-black">{selectedBox.w_description || cDescription}</label>

        <p className="profile-info">
          <strong>ജയറാണി ഈ വി</strong>
          <br />
          WhatsApp:{" "}
          <a
            href="https://wa.me/919746812212"
            target="_blank"
            rel="noopener noreferrer"
          >
            9746812212
          </a>
        </p>
      </div>
    );
  };
  return (
    <div className="mb-5 mt-3">
      <h2 className="  text-center mb-3">രാശിഫലം</h2>

      <div className="scrollable-container    ">
        <div className="radio-buttons">
          <label className=" text-black text-nowrap">
            <input
              type="radio"
              value="daily"
              className="m-2"
              checked={selectedOption === "daily"}
              onChange={handleOptionChange}
            />
            ദിവസ ഫലം
          </label>
          <label className=" text-black text-nowrap">
            <input
              type="radio"
              value="weekly"
              className="m-2"
              checked={selectedOption === "weekly"}
              onChange={handleOptionChange}
            />
            വാര ഫലം
          </label>
          <label className=" text-black text-nowrap">
            <input
              type="radio"
              value="monthly"
              className="m-2 text-black"
              checked={selectedOption === "monthly"}
              onChange={handleOptionChange}
            />
            മാസ ഫലം
          </label>
        </div>
      </div>

      <div className=" row">
        <div className=" col-md-6">{getHoroscopeContent()}</div>

        <div className="  scrollable-containerside col-md-6 ">{renderSelectedBoxDetails()}</div>
      </div>
    </div>
  );
};

export default HoroscopeComponent;
