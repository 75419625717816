import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Horscopesec from "../components/Horscope/Horscopesec";

const ServiceDetail = () => {
  const { service } = useParams();
  const [serviceData, setServiceData] = useState(null);

  useEffect(() => {
    if (service) {
      const fetchServiceData = async () => {
        try {
          const response = await axios.get(
            "https://lunarsenterprises.com:5005/jyothisham/list/service"
          );
          const foundService = response.data.data.find(
            (s) =>
              s.s_name.toLowerCase().replace(/\s+/g, "-") ===
              service.toLowerCase()
          );
          setServiceData(foundService);
        } catch (error) {
          console.error(error);
        }
      };

      fetchServiceData();
    }
  }, [service]);

  if (!serviceData) {
    return <div>Loading...</div>;
  }

  // Split the description content for overlay and below sections
  const overlayDescription = serviceData.s_description.slice(0, 100); // First 100 characters
  const remainingDescription = serviceData.s_description.slice(100); // Rest of the content

  return (
    
    <>
    <br/>
    <br/>
    <br/>
      <div className="container mt-5 mb-5">
        <div className="col-12">
          <div className="row">
            <div className="col-md-4">
              <div className="mb-5 justify-content-center align-items-center d-flex">
                <Horscopesec />
              </div>
            </div>

            <div className="col-md-8 mt-3">
              {/* <div className="headcheck"> */}
              < label className="productsizenamehorstitle text-center text-black">{serviceData.s_name}</label>
              {/* </div> */}
          
                <div className="col-12">
                  <img
                    src={`https://lunarsenterprises.com:5005/${serviceData.s_image}`}
                    alt={serviceData.s_name}
                    className="service-imageleftrightr"
                  />
                  <div className="heghtpre">
                
                    <label className="productsizenamesideplace text-black mt-3">{serviceData.s_description}</label>
                    </div>
            
                </div>
            
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceDetail;
