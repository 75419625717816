import "@splidejs/react-splide/css";

import React, { useEffect, useRef, useState } from "react";

import { Button, Card, Modal, Spinner } from "react-bootstrap";

import { useTranslation } from "react-i18next";
import $ from "jquery";
import "select2";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import axios from "axios";
import Testmonial from "../components/Testmonial";
import god from "../public/Assets/god.png";
import horseshoe from "../public/Assets/horseshoe.png";
import sun from "../public/Assets/sun.png";
import lotus from "../public/Assets/lotus.png";

import peace from "../public/Assets/peace.png";

import Aone from "../public/Assets/Aone.png";

import taurus from "../public/Assets/taurus.png";
import gemini from "../public/Assets/gemini.png";

import heth from "../public/images/shani-1679293486.jpg";

import cancer from "../public/Assets/cancer.png";
import Leo from "../public/Assets/Leo.png";
import Virgo from "../public/Assets/Virgo.png";
import Libra from "../public/Assets/Libra.png";

import blog2 from "../public/Assets/blog2.jpg";
import blog3 from "../public/Assets/blog3.jpg";
import three from "../public/Assets/three.png";

import blog1 from "../public/Assets/blog1.jpg";
import Scorpio from "../public/Assets/Scorpio.png";
import cap from "../public/Assets/cap.png";
import aqu from "../public/Assets/aqu.png";
import pis from "../public/Assets/pis.png";
import sag from "../public/Assets/sag.png";
import vasthu from "../public/Assets/vasthu.jpg";

import pothutham from "../public/Assets/pothutham.webp";
import health from "../public/Assets/health.jpg";

import art3 from "../public/Assets/art3.jpg";
import art2 from "../public/Assets/art2.webp";
import art4 from "../public/Assets/art4.png";
import art5 from "../public/Assets/art5.jpg";

import taurushoroscopeastrologyzodiac from "../public/Assets/taurus-horoscope-astrology-zodiac.png";

import geminihoroscopeastrologyzodiac from "../public/Assets/gemini-horoscope-astrology-zodiac.png";
import cancerhoroscopeastrologyzodiac from "../public/Assets/cancer-horoscope-astrology-zodiac.png";

import leohoroscopeastrologyzodiac from "../public/Assets/leo-horoscope-astrology-zodiac.png";
import virgohoroscopeastrologyzodiac from "../public/Assets/virgo-horoscope-astrology-zodiac.png";
import scorpiohoroscopeastrologyzodiac from "../public/Assets/scorpio-horoscope-astrology-zodiac.png";
import librahoroscopeastrologyzodiac from "../public/Assets/libra-horoscope-astrology-zodiac.png";
import sagittariushoroscopeastrologyzodiac from "../public/Assets/sagittarius-horoscope-astrology-zodiac.png";
import aquariushoroscopezodiacfortune from "../public/Assets/aquarius-horoscope-zodiac-fortune.png";
import pisceshoroscopeastrologyzodiac from "../public/Assets/pisces-horoscope-astrology-zodiac.png";

import aries from "../public/Assets/aries.png";
import AstrologyProductsGrid from "../components/Astrologhy/Astrologhy";
import HoroscopeComponent from "../components/Horscope/Horscope";
import YourMainComponent from "../components/Mapcomponent";
import VideoContent from "../components/VideoContent";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UserForm from "../NewScreens/HomeNewScreen";
import Wheel_Horoscopevideo from "../public/images/Wheel_Horoscopevideo.mp4";

export default function HomePage() {
  const { t } = useTranslation();

  const selectRef = useRef(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    message: "",
    services: "",
    date: "",
  });

  const [servicesApi, setServicesApi] = useState([]);

  useEffect(() => {
    const servicesList = async () => {
      try {
        const servicesResp = await axios.get(
          "https://lunarsenterprises.com:5005/jyothisham/list/service"
        );
        setServicesApi(servicesResp.data.data);
        console.log(servicesResp.data.data, "servicesRespData");
      } catch (error) {
        console.error(error);
      }
    };

    servicesList();
  }, []);

  useEffect(() => {
    $(selectRef.current).select2({
      placeholder: "ഒരു ഓപ്ഷൻ തിരഞ്ഞെടുക്കുക",
      width: "100%",
    });

    $(selectRef.current).on("change", (event) => {
      setSelectedOption(event.target.value);
      setFormData((prevData) => ({
        ...prevData,
        services: event.target.value,
      }));
    });

    return () => {
      $(selectRef.current).select2("destroy");
    };
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handlePhoneChange = (value) => {
    setFormData({ ...formData, phone: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await axios.post(
        "https://lunarsenterprises.com:5005/jyothisham/add/booking",
        {
          first_name: formData.firstName,
          last_name: formData.lastName,
          mobile: formData.phone,
          email: formData.email,
          message: formData.message,
          service: formData.services,
          date: formData.date,
        }
      );
      setLoading(false);
      setShowModalbook(true);
      setFormData({
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        message: "",
        services: "",
        date: "",
      });
    } catch (error) {
      setLoading(false);
      console.error("There was an error sending the message!", error);
    }
  };

  const boxestone = [
    { title: "Aries", image: "/public/Assets/Diamond.png" },
    { title: "Taurus", image: "/Assets/taurus.png" },
    { title: "Gemini", image: "/Assets/gemini.png" },

    { title: "Cancer", image: "/Assets/cancer.png" },
    { title: "Leo", image: "/Assets/Leo.png" },
    { title: "Virgo", image: "/Assets/Virgo.png" },
    { title: "Libra", image: "/Assets/Libra.png" },
    { title: "Scorpio", image: "/Assets/Scorpio.png" },
    { title: "Capricorn", image: "/Assets/cap.png" },
    { title: "Aquarius", image: "/Assets/aqu.png" },
    { title: "Pisces", image: "/Assets/pis.png" },
    { title: "Sagittarius", image: "/Assets/sag.png" },
  ];

  const boxestitle = [{ id: 1, title: "Horoscope" }];

  const boxeshorcopse = [
    {
      title: "Astrological FORECAST",
      image: blog1,
      subtitle:
        "Important Festivals and their Dates based on Hindu Lunar Calendar. ",
    },
    {
      title: "Astrological Reports",
      image: blog2,
      subtitle: "Mercury is the lord of communications.",
    },
    {
      title: "HOROSCOPE READING",
      image: three,
      subtitle:
        'The word "horoscope" is derived from the Greek words "hora" (time) and "scopos" (observer), meaning "time observer.',
    },

    {
      title: "LOVE COMPATIBILTY",
      image: blog3,
      subtitle:
        "Understand compatibility in romantic relationships through the wisdom of the stars. ",
    },
  ];

  const boxeshorcopselower = [
    { title: "Vasthu", image: vasthu, subtitle: "Spiritual energy flow." },
    {
      title: "Horoscope Matching",
      image: blog3,
      subtitle: "Harmony through astrology.",
    },
    {
      title: "Jathakam",
      image: pothutham,
      subtitle: "Marriage is a sacred bond.",
    },

    {
      title: "Health and Astrology",
      image: health,
      subtitle: "Health Problem Solution",
    },
  ];

  const boxeshorcopseblog = [
    {
      title: "ജ്യോതിഷത്തിന്റെ ചരിത്രം",
      image: art5,
      link: "https://en.wikipedia.org/wiki/History_of_astrology",
      subtitle:
        "ജ്യോതിഷത്തിന്റെ പുരാതന കാലത്തുനിന്നും ആധുനിക കാലത്തേക്കുള്ള വികാസത്തെ ഈ ലേഖനം പരിശോധിക്കുന്നു.",
    },
    {
      title: "ജ്യോതിഷ സാദൃശ്യം",
      image: art2,
      subtitle:
        "ജ്യോതിഷ ചിഹ്നങ്ങൾ ഏതൊക്കെയാണ് പ്രണയപരവും വ്യക്തിപരവുമായ ബന്ധങ്ങളെ സ്വാധീനിക്കുന്നു എന്ന് ഈ ലേഖനം ചർച്ച ചെയ്യുന്നു.",
      link: "https://en.wikipedia.org/wiki/Astrological_compatibility",
    },
    {
      title: "ജ്യോതിഷ കാലഘട്ടം",
      image: art3,
      subtitle:
        "ജ്യോതിഷ കാലഘട്ടങ്ങളുടെ ആശയം, ഏതാണ്ട് 2,150 വർഷം നീണ്ടുനിൽക്കുന്ന കാലയളവുകളെ വിശദീകരിക്കുന്ന ലേഖനം.",
      link: "https://en.wikipedia.org/wiki/Astrological_age",
    },
    {
      title: "വേദ ജ്യോതിഷം",
      image: art4,
      subtitle:
        "നക്ഷത്രങ്ങളുടെ ജ്ഞാനം വഴി പ്രണയബന്ധങ്ങളിൽ സാദൃശ്യം മനസിലാക്കുക.",
      link: "https://en.wikipedia.org/wiki/Hindu_astrology",
    },
  ];

  const boxeshorcopseunder = [
    { title: "ARIES", image: Aone, subtitle: "Confident, Energetic, Bold" },
    {
      title: "TAURUS",
      image: taurushoroscopeastrologyzodiac,
      subtitle: "Reliable, Patient, Determined",
    },
    {
      title: "GEMINI",
      image: geminihoroscopeastrologyzodiac,
      subtitle: "Curious, Adaptable, Sociable",
    },
    {
      title: "CANCER",
      image: cancerhoroscopeastrologyzodiac,
      subtitle: "Emotional, Nurturing, Intuitive",
    },
    {
      title: "LEO",
      image: leohoroscopeastrologyzodiac,
      subtitle: "Generous, Charismatic, Leader",
    },
    {
      title: "VIRGO",
      image: virgohoroscopeastrologyzodiac,
      subtitle: "Analytical, Practical, Diligent",
    },
    {
      title: "LIBRA",
      image: librahoroscopeastrologyzodiac,
      subtitle: "Diplomatic, Charming, Fair",
    },
    {
      title: "SCORPIO",
      image: scorpiohoroscopeastrologyzodiac,
      subtitle: "Passionate, Mysterious, Intense",
    },
    {
      title: "SAGITTARIUS",
      image: sagittariushoroscopeastrologyzodiac,
      subtitle: "Adventurous, Optimistic, Independent",
    },
    {
      title: "CAPRICORN",
      image: cancerhoroscopeastrologyzodiac,
      subtitle: "Disciplined, Ambitious, Practical",
    },
    {
      title: "AQUARIUS",
      image: aquariushoroscopezodiacfortune,
      subtitle: "Innovative, Idealistic, Humanitarian",
    },
    {
      title: "PISCES",
      image: pisceshoroscopeastrologyzodiac,
      subtitle: "Empathetic, Imaginative, Gentle",
    },
  ];

  const [showAll, setShowAll] = useState(false);

  const handleToggleShow = () => {
    setShowAll(!showAll);
  };

  const [activeIndex, setActiveIndex] = useState(null);

  const handleBoxClick = (index) => {
    setActiveIndex(index);
    setSelectedBoxside(boxestitle[index]);
  };
  // horspcope modal
  const [show, setShow] = useState(false);

  const [selectedBox, setSelectedBox] = useState({ title: "", image: "" });
  const [selectedBoxside, setSelectedBoxside] = useState(boxestitle[0]);

  const handleClose = () => setShow(false);
  const handleShow = (box) => {
    setSelectedBox(box);
    setShow(true);
  };

  const [showModalbook, setShowModalbook] = useState(false);

  // Api for monthly horoscope_data

  const boxes = [
    { title: "Aries", image: aries },
    { title: "Taurus", image: taurus },
    { title: "Gemini", image: gemini },

    { title: "Cancer", image: cancer },
    { title: "Leo", image: Leo },
    { title: "Virgo", image: Virgo },
    { title: "Libra", image: Libra },
    { title: "Scorpio", image: Scorpio },
    { title: "Capricorn", image: cap },
    { title: "Aquarius", image: aqu },
    { title: "Pisces", image: pis },
    { title: "Sagittarius", image: sag },
  ];

  const [horoscopeData, setHoroscopeData] = useState(null);

  const fetchHoroscopeData = async () => {
    if (selectedBox) {
      try {
        const Time = {
          status: selectedOption,
        };
        const response = await axios.post(
          "https://lunarsenterprises.com:5005/jyothisham/list/weekly/horoscope"
        );
        setHoroscopeData(response.data.data);
        console.log(response.data); // Log the complete response to see the structure
      } catch (error) {
        console.error("Error fetching horoscope data:", error);
      }
    }
  };

  useEffect(() => {
    fetchHoroscopeData();

    // Cleanup function to reset the horoscopeData when the component unmounts
    return () => setHoroscopeData(null);
  }, [selectedBox]);

  // Api for Love horoscope_data

  const boxestonelove = [
    { title: "Aries", image: aries },
    { title: "Taurus", image: taurus },
    { title: "Gemini", image: gemini },
    { title: "Cancer", image: cancer },
    { title: "Leo", image: Leo },
    { title: "Virgo", image: Virgo },
    { title: "Libra", image: Libra },
    { title: "Scorpio", image: Scorpio },
    { title: "Capricorn", image: cap },
    { title: "Aquarius", image: aqu },
    { title: "Pisces", image: pis },
    { title: "Sagittarius", image: sag },
  ];

  const [selectedImage, setSelectedImage] = useState("");
  const [selectedImagefemale, setSelectedImagefemale] = useState("");
  const [selectedBoy, setSelectedBoy] = useState("");
  const [selectedGirl, setSelectedGirl] = useState("");
  const [LovehoroscopeData, setLoveHoroscopeData] = useState(null);

  const handleShowlov = (box) => {
    setSelectedImage(box ? box.image : "");
    setSelectedBoy(box ? box.title : "");
  };

  const handleShowlovfemale = (box) => {
    setSelectedImagefemale(box ? box.image : "");
    setSelectedGirl(box ? box.title : "");
  };

  const [result, setResult] = useState("");
  const Loveresponse = async () => {
    if (selectedBoy && selectedGirl) {
      setResult(true);
      try {
        const response = await axios.get(
          `https://lunarsenterprises.com:5005/jyothisham/signmatch/calculator?boy=${selectedBoy}&girl=${selectedGirl}`
        );
        setLoveHoroscopeData(response.data);

        console.log(response.data);

        setResult(false);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const renderContent = () => {
    switch (activeIndex) {
      case 1:
        return (
          <div>
            <div className="scrollable-container">
              <div className="box-gridlove">
                <div className="box">
                  <img
                    src={selectedImage || gemini}
                    alt="boxtitle"
                    className="box-image"
                  />
                </div>

                <div className="box">
                  <img
                    src={selectedImagefemale || aries}
                    alt="boxtitle"
                    className="box-image"
                  />
                </div>
                <div>
                  <h6>Male </h6>
                </div>
                <div>
                  <h6>Female </h6>
                </div>

                <div className="d-flex justify-content-center">
                  <select
                    className="selectlove"
                    onChange={(e) =>
                      handleShowlov(
                        boxestonelove.find(
                          (box) => box.title === e.target.value
                        )
                      )
                    }
                  >
                    <option value="">Select a zodiac sign</option>
                    {boxestonelove.map((box, index) => (
                      <option key={index} value={box.title}>
                        {box.title}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="d-flex justify-content-center">
                  <select
                    className="selectlove"
                    onChange={(e) =>
                      handleShowlovfemale(
                        boxestonelove.find(
                          (box) => box.title === e.target.value
                        )
                      )
                    }
                  >
                    <option value="">Select a zodiac sign</option>
                    {boxestonelove.map((box, index) => (
                      <option key={index} value={box.title}>
                        {box.title}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="buttonstylelove mt-3">
              {result ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                <div>
                  {LovehoroscopeData && (
                    <div>
                      <div>
                        <h6>Male Name : {LovehoroscopeData.data.sname}</h6>{" "}
                      </div>
                      <div>
                        <h6>Female Name : {LovehoroscopeData.data.fname}</h6>
                      </div>
                      <div>
                        <h6>
                          Percentage : {LovehoroscopeData.data.percentage}%
                        </h6>
                      </div>
                      <div>
                        {" "}
                        <h6>
                          {LovehoroscopeData.data.result}{" "}
                          <i class="fa-solid fa-heart redlove"></i>
                        </h6>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>

            <div className="buttonstylelove mt-5">
              <Button onClick={Loveresponse}>Love Matching</Button>
            </div>
          </div>
        );
      case 2:
        return (
          <div className="scrollable-container">
            <div className="box-grid  ">
              {boxestone.map((box, index) => (
                <div
                  className="box"
                  key={index}
                  onClick={() => handleShow(box)}
                >
                  <img src={box.image} alt={box.title} className="box-image" />
                  <h3 className="Handlee">{box.title}</h3>
                </div>
              ))}
            </div>
          </div>
        );
      default:
        return (
          <>
            <div className="scrollable-container">
              <div className="box-grid  ">
                {boxes.map((box, index) => (
                  <div
                    className="box"
                    key={index}
                    onClick={() => handleShow(box)}
                  >
                    <img
                      src={box.image}
                      alt={box.title}
                      className="box-image"
                    />
                    <h3 className="Handlee">{box.title}</h3>
                  </div>
                ))}
              </div>
            </div>
          </>
        );
    }
  };

  const handleOptionChange = (event) => setSelectedOption(event.target.value);

  const [servicesApidata, setServicesApidata] = useState([]);

  useEffect(() => {
    const servicesListdata = async () => {
      try {
        const servicesResp = await axios.get(
          "https://lunarsenterprises.com:5005/jyothisham/list/service"
        );
        setServicesApi(servicesResp.data.data);
      } catch (error) {
        console.error(error);
      }
    };

    servicesListdata();
  }, []);

  const [servicesApii, setServicesApii] = useState([]);
  const [displayedProducts, setDisplayedProducts] = useState([]);

  // Function to get 8 random services from the list
  const getRandomProducts = (products, count) => {
    const shuffled = [...products].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, count); // Get the first 8 shuffled items
  };

  useEffect(() => {
    const servicesList = async () => {
      try {
        const servicesResp = await axios.get(
          "https://lunarsenterprises.com:5005/jyothisham/list/service"
        );
        setServicesApii(servicesResp.data.data);
        setDisplayedProducts(getRandomProducts(servicesResp.data.data, 8)); // Initialize with 8 random products
      } catch (error) {
        console.error(error);
      }
    };

    servicesList();
  }, []);

  // Change products every 20 minutes (1200000 ms)
  useEffect(() => {
    const interval = setInterval(() => {
      if (servicesApi.length > 0) {
        setDisplayedProducts(getRandomProducts(servicesApi, 8));
      }
    }, 1200000); // 20 minutes in milliseconds

    return () => clearInterval(interval); // Clean up interval on component unmount
  }, [servicesApi]);

  return (
    <>
      {/* Hero banner design */}

    
      <div className="margintopmain ">
        <div className="headercolor-R contentstyle row container-fluid ">
          <div className="row ">
            <div className="col-md-6">
              <label className="h1style-R">ജ്യോതിഷം</label>
            </div>
            <div className=" col-md-6 bg-text-black stright">
              <div className="contentstyle ">
   
                <div className="mailnumber-R d-md-flex">
                  <FontAwesomeIcon icon={faPhone} style={{color:"white"}} />
                  <span className="contact-text mt-1 text-white ms-1 me-2 text-nowrap">+91 9746812212</span>
                </div>

                <div className="mailnumber-R d-md-flex ">
                  <FontAwesomeIcon style={{color:"white"}} icon={faEnvelope} />
                  <span className="contact-text mt-1 text-white ms-1 me-2">astrology@gmail.com</span>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>

    
     

      {/* Section======?? */}
      <div className=" backgroundimagehom bgcolorhome">
        <div className="container   tablemargin">
          <div className="row">
            {/* lefside------- */}
            <div className=" maform col-md-4 mt-5">
              <div className=" form-wrapper">
                <div className="heading_name">
                  <label className="h2-center">ജനന വിവരങ്ങൾ </label>
                </div>
                <div className="mt-5 ">
                  <UserForm />
                </div>
              </div>
            </div>
            {/* center------- */}

            {/* <div className="col-md-4 mt-5">
            <div class="leftcol">
              <div class="head">
                <span className="suphead">ജ്യോതിഷം</span>
                <div
                  class="support"
                  style={{
                    float: "right",
                    fontSize: "10px",
                    marginRight: "10px",
                    borderLeft: "1px solid lightgray",
                  }}
                  // onClick={() => trackpopup()}
                ></div>
                <div
                  class="support"
                  style={{
                    float: "right",
                    fontSize: "10px",
                    marginRight: "33px",
                  }}
                ></div>
                <div class="clear"></div>
              </div>
              <div class="clear"></div>
              <div id="cssmenu">
                <div id="menu-button"></div>
                <ul className="">
                
                  <li class="newtag">
                    <a href="//astrology.mathrubhumi.com/horoscopes/super-horoscope.php">
                    രാശിഫലം
                    </a>
                  </li>
                  
                  
                  <li class="newtag tag1">
                    <img
                      src="https://astrology.mathrubhumi.com/images/new-tag.gif"
                      style={{ width: 30 }}
                    />
                    <a
                      href="//astrology.mathrubhumi.com/horoscopes/yearguide.php"
                      class="newtag"
                    >
                      Year Guide 2025
                    </a>
                  </li>
                  <li class="newtag">
                    <a href="//astrology.mathrubhumi.com/horoscopes/marriage-matching.php">
                      Marriage Compatibility
                    </a>
                  </li>
                  <li class="newtag">
                    <a href="//astrology.mathrubhumi.com/horoscopes/gem-recommendation.php">
                      Gem Recommendation
                    </a>
                  </li>
                  <li class="newtag">
                    <a href="//astrology.mathrubhumi.com/horoscopes/career-predictions.php">
                      Career Predictions
                    </a>
                  </li>
                  <li class="newtag">
                    <a href="//astrology.mathrubhumi.com/horoscopes/numerology.php">
                      Numerology Report
                    </a>
                  </li>
                  <li class="newtag">
                    <a href="//astrology.mathrubhumi.com/horoscopes/wealth-fortune-report.php">
                      Wealth &amp; Fortune Report
                    </a>
                  </li>
                  <li class="newtag">
                    <a href="//astrology.mathrubhumi.com/horoscopes/education-horoscope.php">
                      Education Horoscope
                    </a>
                  </li>
                  <li class="newtag  tag1">
                    <img
                      src="https://astrology.mathrubhumi.com/images/new-tag.gif"
                      style={{ width: 30 }}
                    />
                    <a
                      href="//astrology.mathrubhumi.com/horoscopes/jupiter-transit.php"
                      class="newtag"
                    >
                      Jupiter Transit Report
                    </a>
                  </li>
                  <li class="newtag">
                    <a href="//astrology.mathrubhumi.com/horoscopes/rahu-ketu-transit.php">
                      Rahu-Ketu Transit Report
                    </a>
                  </li>
                  <li class="newtag  tag1">
                    <img
                      src="https://astrology.mathrubhumi.com/images/new-tag.gif"
                      style={{ width: 30 }}
                    />
                    <a
                      href="//astrology.mathrubhumi.com/horoscopes/saturn-transit.php"
                      class="newtag"
                    >
                      Saturn Transit Report
                    </a>
                  </li>
                  <li class="newtag">
                    <a href="//astrology.mathrubhumi.com/horoscopes/astrology-scheduler.php">
                      Astro Scheduler
                    </a>
                  </li>
                </ul>
              </div>
              <div class="clear"></div>
            </div>
          </div> */}
            <div className="col-md-6 mt-5">
              <VideoContent />
            </div>

            {/* rightside------- */}

            {/* <div className="col-md-2  mt-5">
            <div class="col2">
              <div class="ad-300">
                <a href="//astrology.mathrubhumi.com/horoscopes/yearguide.php">
                  <img src="//astrology.mathrubhumi.com/images/yearguide-2025-mal.jpg" />
                </a>
              </div>
              <div class="ad-300">
                <a href="//astrology.mathrubhumi.com/horoscopes/transit-combo.php">
                  <img src="//astrology.mathrubhumi.com/images/300x250-gojaraphalam.jpg" />
                </a>
              </div>
              <div class="ad-300">
                <a href="//astrology.mathrubhumi.com/horoscopes/jupiter-transit.php">
                  <img src="//astrology.mathrubhumi.com/images/300x250-jupter-transit.jpg" />
                </a>
              </div>
              <div class="ad-300">
                <a href="//astrology.mathrubhumi.com/horoscopes/astrology-scheduler.php">
                  <img src="//astrology.mathrubhumi.com/images/300x250_schedulernew2021.jpg" />
                </a>
              </div>
              <div class="ad-300">
                <a href="//astrology.mathrubhumi.com/horoscopes/gem-numerology-indepth-combo.php">
                  <img src="//astrology.mathrubhumi.com/images/300x250_indepth.jpg" />
                </a>
              </div>
              <div class="ad-300">
                <a href="//astrology.mathrubhumi.com/horoscopes/saturn-yearguide-combo.php">
                  <img src="//astrology.mathrubhumi.com/images/300x250adss_new.jpg" />
                </a>
              </div>
              <div class="clear"></div>
              <div class="ad-300">
                <a href="//astrology.mathrubhumi.com/horoscopes/life-document.php">
                  <img src="//astrology.mathrubhumi.com/images/printed-book-widget.jpg" />
                </a>
              </div>
              <div class="clear"></div>
              <div class="ad-300-600">
                <a href="//astrology.mathrubhumi.com/horoscopes/education-horoscope.php">
                  <img src="//astrology.mathrubhumi.com/images/300x600-education-report-2.jpg" />
                </a>
              </div>
              <div class="clear"></div>
            </div>
          </div> */}

            <div className=" hidesidepic col-md-2 mt-5">
              <div class="leftcol">
                <div class="">
                  <div
                    class="support"
                    style={{
                      float: "right",
                      fontSize: "10px",
                      marginRight: "10px",
                      borderLeft: "1px solid lightgray",
                    }}
                    // onClick={() => trackpopup()}
                  ></div>
                  <div
                    class="support"
                    style={{
                      float: "right",
                      fontSize: "10px",
                      marginRight: "33px",
                    }}
                  ></div>
                  <div class="clear"></div>
                </div>

                <div className=" heightside  pt-3">
                  {displayedProducts.map((service, index) => (
                    <div key={index} class="newtag">
                      <a
                        className=""
                        href={`/services/${encodeURIComponent(
                          service.s_name.toLowerCase().replace(/\s+/g, "-")
                        )}`}
                      >
                        {" "}
                        <img
                          src={`https://lunarsenterprises.com:5005/${service.s_image}`}
                          alt={service.s_name}
                          className="service-imageside"
                        />
                        <p>
                          <a
                            className=""
                            href={`/services/${encodeURIComponent(
                              service.s_name.toLowerCase().replace(/\s+/g, "-")
                            )}`}
                          >
                            {/* {service.s_name} */}
                          </a>
                        </p>
                      </a>
                    </div>
                  ))}
                </div>
              </div>
              <div class="clear"></div>
            </div>
          </div>
        </div>
      </div>

      <div className="herobannerbanner"></div>
      {/* Services */}

      <div className="container   ">
        <div className="col-md-12 ">
          <HoroscopeComponent />
        </div>
      </div>

      {/* <Modal
        centered
        dialogClassName="custom-modal"
        size="lg"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <img
              src={selectedBox ? selectedBox.image : ""}
              alt={selectedBox ? selectedBox.title : ""}
              className="modal-imag"
              style={{ height: 80 }}
            />
            {selectedBox ? selectedBox.title : ""} ({" "}
            {horoscopeData && horoscopeData.month})
          </Modal.Title>
        </Modal.Header>
        <div className="row mt-5 mb-5 m-3">
          <h5> Better Days {horoscopeData && horoscopeData.standout_days}</h5>
          <h5>
            {" "}
            Challenging Days {horoscopeData && horoscopeData.challenging_days}
          </h5>

          <h6>{horoscopeData ? horoscopeData.horoscope_data : ""}</h6>
          <div className="col-8"></div>
        </div>

        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Back
          </Button>
        </Modal.Footer>
      </Modal> */}

      {/*  Get latest daily zodiac*/}
      <div className="  ">
        <div className="container  mt-5 mb-5">

        < label className="productsizenamehorstitlemain text-black text-center"> നിങ്ങളുടെ ഗ്രഹങ്ങളുമായി ബന്ധമുള്ള വിപുലമായ <br/> ജ്യോതിഷ പരിഹാരങ്ങൾ
        </label>

          <h2 className="blog ">
         
          </h2>

          <AstrologyProductsGrid />
        </div>
      </div>

      {/* {blog} */}

      {/* <div className="bgcolor hidelotusart">
        <div className="container">
          <h1 className=" blog">{t("Our Articles for you")}</h1>
          <div className="col-lg-12 mt-5 container ">
            <div className="box-gridblog ">
              {boxeshorcopseblog.map((box, index) => (
                <div className="  " key={index}>
                  <div className="secboxblog ">
                    <img
                      src={box.image}
                      alt={box.title}
                      className="secboxzodblog"
                    />
                  </div>
                  <div className="dflexcent ">
                    <div className="mainheadingblog">
                      <h4 className="Zodiablog mt-2 ">{box.title}</h4>

                      <Card.Title className="productsizename ">
                        {box.subtitle}
                      </Card.Title>

                    
                      <div className="seemorearticleviewmore col-4 ">
                        <a href={box.link}>
                          <p className="viewmore">കൂടുതല്‍ കാണുക</p>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div> */}

      {/* {blog mob} */}

      {/* <div className="bgcolor  hideloart   ">
        <div className="">
          <h1 className=" blog mt-5">{t("Our Articles for you")}</h1>
          <div className=" container col-lg-12 mt-5  ">
            <div className="box-gridblog">
              {boxeshorcopseblog.map((box, index) => (
                <div className=" " key={index}>
                  <div className=" ">
                    <img
                      src={box.image}
                      alt={box.title}
                      className="secboxzodblog"
                    />
                  </div>
                  <div className="dflexcent col-12 mb-3 ">
                    <div className="mainheadingblog ">
                      <p className=" mt-2 ">{box.title}</p>
                      <Card.Title className="productsizename ">
                        {box.subtitle}
                      </Card.Title>
                      <a href={box.link}>
                        <div className="seemorearticle ms-3">
                          <p className="viewmore"> കൂടുതല്‍ കാണുക</p>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div> */}
      {/* lower services */}

      {/* <div className="hidelotus">
        <div className="container mt-5 mb-5">
          <h1 className=" Zodia">
            {t("Innovative Services for Modern Challenges")}
          </h1>
          <p className="Zodiaser">
            {t(
              "We combine expertise and creativity to address your unique challenges and deliver impactful solutions."
            )}
          </p>
          <div className="col-lg-10 mt-5 container">
            <div className="box-gridZodiaser">
              <div className="row">
                <div className="col-1"></div>
                <div className="col-5">
                  <div className="boxZodiaser">
                    <div className="secboxzodiaser ">
                      <img src={vasthu} className="boximagezodia" />

                      <div></div>
                    </div>

                    <h4 className="Zodiaheadser ">Vasthu</h4>
                    <p className="SubZodiaheadser">Spiritual energy flow.</p>
                  </div>
                  <div className="col-6"></div>
                </div>
                <div className="col-1"></div>
                <div className="col-5">
                  <h1 className="serside">
                    {t("Get Latest")} <br />
                    {t("Forecast and")} <br />
                    {t("Analysis for")}
                    <br /> {t("Success")}
                  </h1>
                </div>
              </div>

              <div className="row">
                <div className="col-4"></div>

                <div className="col-5 servicesstyle">
                  <div className="boxZodiaser">
                    <div className="secboxzodiaser ">
                      <img src={blog3} className="boximagezodia" />

                      <div></div>
                    </div>

                    <h4 className="Zodiaheadser ">Horoscope Matching</h4>
                    <p className="SubZodiaheadser">
                      {" "}
                      Harmony through astrology.{" "}
                    </p>
                  </div>
                  <div className="col-6"></div>
                </div>
              </div>

              <div className="row">
                <div className="col-1"></div>

                <div className="col-5 servicesstyle">
                  <div className="boxZodiaser">
                    <div className="secboxzodiaser ">
                      <img src={pothutham} className="boximagezodia" />

                      <div></div>
                    </div>

                    <h4 className="Zodiaheadser ">Jathakam</h4>
                    <p className="SubZodiaheadser">
                      Marriage is a sacred bond.
                    </p>
                  </div>
                </div>

                <div className="col-1"></div>

                <div className="col-5 servicesstyle">
                  <div className="boxZodiaser">
                    <div className="secboxzodiaser ">
                      <img src={health} className="boximagezodia" />

                      <div></div>
                    </div>

                    <h4 className="Zodiaheadser ">Health and Astrology</h4>
                    <p className="SubZodiaheadser">Health Problem Solution</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="container hidelo  mt-5 mb-5">
          <h1 className=" Zodia">
            {t("Innovative Services for Modern Challenges")}
          </h1>
          <p className="Zodiaser">
            {t(
              "We combine expertise and creativity to address your unique challenges and deliver impactful solutions."
            )}
          </p>
          <div className="col-lg-10 mt-5 container">
            <div className="box-gridZodia">
              {boxeshorcopselower.map((box, index) => (
                <div className="boxZodia" key={index}>
                  <div className="secboxzodia mt-4">
                    <img
                      src={box.image}
                      alt={box.title}
                      className="boximagezodia"
                    />
                  </div>
                  <h4 className="Zodiahead mt-2">{box.title}</h4>
                  <p className="SubZodiahead">{box.subtitle} </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div> */}

      {/* Booking */}

      <div className="bgcolorunder paddinhstyle p-5  ">
        <div className="container p-5">
          <div className="underhorse col-12">
            <h2 className="underhorsemain">ബുക്ക് ചെയ്യുക</h2>
            <label className="ZodiaserBook text-black  mb-4">
              ഇന്ന് തന്നെ നിങ്ങളുടെ അപോയിന്റ്മെന്റ് ഷെഡ്യൂൾ ചെയ്ത്
              ജ്യോതിഷത്തിന്റെ ലോകത്തേക്കുള്ള യാത്ര ആരംഭിക്കുക
            </label>
            <form onSubmit={handleSubmit} className="">
              <div className="col-12 align-item-center -flex justify-content-center">
                <div className="  " >
                  <div className="row">
                    <div className="col-md-6 mt-3">
                      <h6 className="bookname">പേര്</h6>
                      <input
                        className="form-control inputbook"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        required
                        placeholder="പേര്"
                      />
                    </div>
                    <div className="col-md-6 mt-3">
                      <h6 className="bookname">അവസാന പേര്</h6>
                      <input
                        className="form-control inputbook"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        required
                        placeholder="അവസാന പേര്"
                      />
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="col-md-6 mt-2">
                      <h6 className="bookname">നിങ്ങളുടെ നമ്പർ*</h6>
                      <PhoneInput
                        country={"in"}
                        value={formData.phone}
                        onChange={handlePhoneChange}
                        inputStyle={{ height: "50px" }}
                        inputClass="form-control inputbook"
                        required
                      />
                    </div>
                    <div className="col-md-6 mt-2">
                      <h6 className="bookname">ഇമെയിൽ</h6>
                      <input
                        className="form-control inputbook"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                        type="email"
                        placeholder="ഇമെയിൽ"
                      />
                    </div>
                  </div>

                  <div className="row mt-md-3">
                    <div className="col-md-6 mt-2">
                      <h6 className="bookname">സേവനങ്ങൾ*</h6>
                      <select ref={selectRef} className="">
                        <option></option> {/* Placeholder for Select2 */}
                        {servicesApi.map((option) => (
                          <option
                            className="optionselect"
                            key={option.value}
                            value={option.value}
                          >
                            {option.s_name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-6 mt-2">
                      <h6 className="bookname">തിയതി*</h6>
                      <input
                        name="date"
                        value={formData.date}
                        onChange={handleChange}
                        required
                        type="date"
                        className="form-control inputbook"
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-3">
                  <h6 className="bookname">സന്ദേശം</h6>
                  <input
                    multiple
                    type="text"
                    className="form-control inputbooknsh"
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <Button
                className="custom-button mt-5"
                title={t("Book Now")}
                type="submit"
                disabled={loading}
              >
                {loading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  "ബുക്ക് ചെയ്യുക "
                )}
              </Button>
            </form>
          </div>
        </div>
      </div>
      <Modal
        centered
        show={showModalbook}
        onHide={() => setShowModalbook(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Success</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("Your message has been sent successfully.Our team Connect soon")}!
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModalbook(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Testmonial />
    </>
  );
}
