




import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaArrowRight } from "react-icons/fa"; // Import an icon for better visual design
import '../../components/Horscope/Horscope.css'


function Horscopesec() {
  const [servicesApi, setServicesApi] = useState([]);

  useEffect(() => {
    const servicesList = async () => {
      try {
        const servicesResp = await axios.get(
          "https://lunarsenterprises.com:5005/jyothisham/list/service"
        );
        setServicesApi(servicesResp.data.data);
      } catch (error) {
        console.error(error);
      }
    };

    servicesList();
  }, []);

  return (
    <div className="services-section mt-3">
      <ul className="services-list">
        {servicesApi.map((service, index) => (
          <li className="service-item" key={index}>
            <a
              className="service-link"
              href={`/services/${encodeURIComponent(
                service.s_name.toLowerCase().replace(/\s+/g, "-")
              )}`}
            >
             < label className="productsizenamehors text-black">{service.s_name}</label>   
              {/* <FaArrowRight className="service-icon" /> */}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Horscopesec;
