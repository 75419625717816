import React, { useState } from "react";

const UserForm = () => {
  // State for form values
  const [formData, setFormData] = useState({
    name: "",
    dobYear: "",
    dobMonth: "",
    dobDay: "",
    language: "Malayalam",
    chartStyle: "Kerala",
    gender: "",
    timeOfBirth: "",
    amPm: "AM",
    cityOfBirth: "",
    email: "",
  });

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const currentYear = new Date().getFullYear();
  const startYear = 1947;

  const years = [];
  for (let year = currentYear; year >= startYear; year--) {
    years.push(year);
  }
  const months = [
    { value: "1", name: "ജനുവരി" },
    { value: "2", name: "ഫെബ്രുവരി" },
    { value: "3", name: "മാർച്ച്" },
    { value: "4", name: "ഏപ്രിൽ" },
    { value: "5", name: "മേയ്" },
    { value: "6", name: "ജൂൺ" },
    { value: "7", name: "ജൂലൈ" },
    { value: "8", name: "ഓഗസ്റ്റ്" },
    { value: "9", name: "സെപ്റ്റംബർ" },
    { value: "10", name: "ഒക്ടോബർ" },
    { value: "11", name: "നവംബർ" },
    { value: "12", name: "ഡിസംബർ" },
  ];

  const days = [];
  for (let i = 1; i <= 31; i++) {
    days.push(i);
  }

  const hours = Array.from({ length: 12 }, (_, i) => i + 1); // 1 to 12
  const minutes = Array.from({ length: 60 }, (_, i) => i + 1); // 1 to 60

  const minutesvalue = [
    { value: "1", name: "AM" },
    { value: "2", name: "PM" },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      setShowModal(true);
    }, 3000);
  };

  return (
    <div className="p-2 ">
      <form onSubmit={handleSubmit}>
        <div className="row ">
          {/* Name Field */}
          <div className="col">
            <div className="form-group">
              <label className="text-black">പേര്</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="form-controlstyle"
                placeholder=" പേര്"
              />
            </div>
          </div>
          <div className="row">
            {/* Date of Birth Day */}
            <div className="col">
              <div className="form-group">
                <label className="text-black">ദിനം</label>
                <select
                  name="dobDay"
                  value={formData.dobDay}
                  onChange={handleChange}
                  className="form-controlstyle"
                >
                  <option value="--">--</option>
                  {days.map((day) => (
                    <option key={day} value={day}>
                      {day}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          {/* Date of Birth Month */}
          <div className="col">
            <div className="form-group">
              <label className="text-black">മാസം</label>
              <select
                name="dobMonth"
                value={formData.dobMonth}
                onChange={handleChange}
                className="form-controlstyle"
              >
                <option value="--">--</option>
                {months.map((month) => (
                  <option key={month.value} value={month.value}>
                    {month.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {/* Date of Birth Year */}
          <div className="col">
            <div className="form-group">
              <label className="text-black">വർഷം</label>
              <select
                name="dobYear"
                value={formData.dobYear}
                onChange={handleChange}
                className="form-controlstyle"
              >
                <option value="--">--</option>
                {/* Add other year options as needed */}
                {years.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
                {/* ... */}
              </select>
            </div>
          </div>
        </div>
        {/* Gender Radio Buttons */}
        <div className="col mt-2">
          <div className="form-group mt-2 ">
            <label className="text-black">പുരുഷൻ / സ്ത്രീ</label>
            <div className="d-flex ">
              <label className="text-black">
                <input
                  className="me-2 ms-2"
                  type="radio"
                  name="gender"
                  value="Male"
                  checked={formData.gender === "Male"}
                  onChange={handleChange}
                />
                പുരുഷൻ
              </label>
              <label className="text-black">
                <input
                  className="me-2 ms-2"
                  type="radio"
                  name="gender"
                  value="Female"
                  checked={formData.gender === "Female"}
                  onChange={handleChange}
                />
                സ്ത്രീ
              </label>
            </div>
          </div>
        </div>

        <div className="row">
          {/* Time of Birth */}
          <div className="col">
            <div className="form-group">
              <label className="text-black">ജനന സമയം</label>
              <div className="d-flex">
                {/* Hour Dropdown */}
                <select
                  name="hour"
                  value={formData.hour}
                  onChange={handleChange}
                  className="form-controlstyle"
                >
                  <option value="">--</option>
                  {hours.map((hour) => (
                    <option key={hour} value={hour}>
                      {hour}
                    </option>
                  ))}
                </select>

                {/* Minute Dropdown */}
                <select
                  name="minute"
                  value={formData.minute}
                  onChange={handleChange}
                  className="form-controlstyle"
                >
                  <option value="--">--</option>
                  {minutes.map((minute) => (
                    <option key="" value={minute}>
                      {minute}
                    </option>
                  ))}
                </select>
                <select
                  name="minute"
                  value={formData.minute}
                  onChange={handleChange}
                  className="form-controlstyle"
                >
                  <option value="AM">--</option>
                  {minutesvalue.map((minutesvalue) => (
                    <option key={minutesvalue.value} value={minutesvalue.value}>
                      {minutesvalue.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          {/* City of Birth */}
          <div className="col">
            <div className="form-group">
              <label className="text-black">ജനനസ്ഥലം</label>
              <input
                type="text"
                name="cityOfBirth"
                value={formData.cityOfBirth}
                onChange={handleChange}
                className="form-controlstyle"
                placeholder="നിങ്ങളുടെ ജനനസ്ഥലം നൽകുക..."
              />
            </div>
          </div>
        </div>

        <div className="mt-4">
          <div className="col justify-content-center d-flex align-items-center">
            <button type="submit" className="button-r btn-primary">
              {isLoading ? (
                <span className="spinner-border spinner-border-sm"></span>
              ) : (
                "ജാതകം ലഭിക്കുക"
              )}
            </button>
          </div>
        </div>
      </form>

      {showModal && (
        <div className="modal d-block" tabIndex="-1">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">ജനന വിവരങ്ങൾ</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowModal(false)}
                ></button>
              </div>
              <div className="modal-body">
                <p>പേര്: {formData.name}</p>
                <p>ജനന വർഷം: {formData.dobYear}</p>
                <p>ജനന മാസം: {formData.dobMonth}</p>
                <p>ജനന ദിവസം: {formData.dobDay}</p>
                <p>പുരുഷൻ / സ്ത്രീ: {formData.gender}</p>
                <p>ജനന സ്ഥലം: {formData.cityOfBirth}</p>
              </div>
              <div className="modal-footer">
                <button className=" button-r btn-primary">
                  <a
                    href="https://wa.me/919746812212"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-white"
                  >
                    കണക്റ്റ് വാട്സ്ആപ്
                  </a>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserForm;
