import React, { useEffect, useState } from "react";
import GoogleMapComponent from "./Mapcomponent";
import { IoCall } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import logoabc from "../public/Assets/logoabc.png";
import { Link } from "react-router-dom";
import axios from "axios";
export default function Footer() {
  const [servicesApi, setServicesApi] = useState([]);

  useEffect(() => {
    const servicesList = async () => {
      try {
        const servicesResp = await axios.get(
          "https://lunarsenterprises.com:5005/jyothisham/list/service"
        );
        setServicesApi(servicesResp.data.data);
      } catch (error) {
        console.error(error);
      }
    };

    servicesList();
  }, []);
  return (
    <>
      <div className="footer  head-footer-bg ">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <img src={logoabc} width="150" height="150" alt="logo" />
              <div className="space5"></div>
              <p></p>
            </div>
            <div className="col-lg-5">
              <label className="text-success fw-bolder">സേവനങ്ങൾ</label>
              <ul className="footer-nav">
                {servicesApi.map((service, index) => (
                  <li key={index}>
                    <a
                      className=""
                      href={`/services/${encodeURIComponent(
                        service.s_name.toLowerCase().replace(/\s+/g, "-")
                      )}`}
                    >
                      {service.s_name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            <div className="col-lg-4 ">
              <label className="text-success fw-bolder">സമീപിക്കുക</label>

              <ul className="footer-nav">
                <li>
                  <MdEmail />
                  <a href="mailto:astrology@gmail.com">
                    <span>astrology@gmail.com</span>
                  </a>
                </li>
                <li>
                  <IoCall />{" "}
                  <a href="tel:9746812212">
                    {" "}
                    <span>9746812212</span>{" "}
                  </a>
                </li>
                <li className="d-flex">
                  <div>
                  <FaLocationDot />
                  </div>
                  <span>
                    Door N0.65/3954,SN Nair Lane,ponoth Road ,
                    Kallor,Kochi-682017,kerala
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="copy-right">
        <div className="copy-right-text">
          Copyright © 2024 . All rights reserved.
        </div>
      </div>
    </>
  );
}
